import { Page } from "@bagel-web/components";

function DashboardIndex() {
  return (
    <Page>
      <Page.Body>
        <p>This is your Artificial.Agency dashboard.</p>
      </Page.Body>
    </Page>
  );
}

export default DashboardIndex;
